.matching-properties-expand-collapse-all-button {
  width: 32px;
}

.matching-properties-expand-collapse-all-button .vsx-btn-child {
  display: flex;
}

.vsx-btn.secondary.matching-properties-expand-collapse-all-button {
  border-color: #c8ccce;
}
