button#back-button .vsx-btn-child {
  display: flex;
  align-items: center;
  margin-right: 4px;
}

.vs-btn-d.vs-outline-secondary-raised-btn {
  background-color: #fff;
  border: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08), 0 2px 3px -1px rgba(0, 0, 0, 0.08);
}

.promotional-models-container {
  flex-wrap: wrap;
}

.publisher-subheader .vs-text.vs-text-type-small {
  color: #757575;
}

.publisher-subheader .vs-text.publisher-id {
  color: #333;
  font-weight: 500;
}

.property-subheader {
  color: #757575;
}

.property-subheader .property-updated-at {
  color: #757575;
}

.property-subheader span {
  line-height: 1.15;
}

.property-subheader span:not(:first-child) {
  border-left: 1px solid #757575;
  margin-left: 5px;
  padding-left: 5px;
}

.table-container-promotional-properties {
  background-color: #fff;
  height: 100%;
  overflow-y: auto;
  margin: 0px;
  border: 0px;
  padding: 0;
  line-height: 1.6;
}

/* remove .match-search-query::before css when USER_CAN_AUTHENTICATE_IN_CIQ is removed */
.match-search-query::before {
  content: "";
  display: inline-block;
  height: 12px;
  width: 12px;
  border-radius: 12px;
  background-color: #ffdf88;
  margin-right: 8px;
}

.match-search-query-row {
  background-color: #fff9ea;
}

.property-website-url {
  word-break: break-word;
  font-weight: 500;
}

.clickable-property-name {
  cursor: pointer;
  word-break: break-all;
}

tr.property-row:hover .property-name {
  border-bottom: 1px solid #333;
}

tr.property-row-with-user-can-authenticate-in-ciq-feature:hover {
  background-color: #fafafa;
}

tr.property-row-with-user-can-authenticate-in-ciq-feature.match-search-query-row:hover {
  background-color: #fff3d2;
}

.property-details-promo-models {
  flex-wrap: wrap;
}

svg.property-table-authentication-status {
  width: auto;
  margin-right: 4px;
  vertical-align: baseline;
}

span.property-table-authentication-status {
  display: inline-block;
  width: 17px;
}

.profile-container {
  margin-left: auto;
  margin-right: auto;
  width: 750px;
}
