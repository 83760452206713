.vs-tab-header {
  padding: 0 24px 0 24px;
}

.vs-tab-header .vs-tab-label {
  border-right: 0;
}

.vs-tab-header .vs-tab-label-clicked {
  font-weight: 500;
}

.vs-tab-header .vs-tab-label-clicked > div {
  border-bottom: 3px solid #49c5b1 !important;
}

.vs-tab-header .vs-tab-label-content.standard {
  padding: 12px 20px;
}
