.property-reach-filter .percentage-input-wrapper {
  display: none;
}

.property-reach-filter .disabled-platform {
  color: var(--brownish-grey);
  filter: opacity(50%);
}

.property-reach-filter .disabled-platform svg {
  filter: grayscale(100%) opacity(60%);
}

.platform-reach-dropdown {
  height: 100%;
  vertical-align: middle;
}
