.badges-filter div.vs-react-select__control {
  height: fit-content;
  padding: 2px;
}

.badges-filter div.vs-react-select__value-container {
  overflow: visible;
}

.badges-filter div.vs-react-select__multi-value {
  margin: 2px;
}

.badges-filter div.vs-default-react-select {
  margin: 0;
}

.badges-filter div.vs-react-select__indicator {
  height: 28px;
}

.badges-filter div.vs-react-select__input input {
  height: 24px;
}
