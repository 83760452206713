:root {
  /* Directly named colors */
  --aqua-marine: #49c5b1;
  --barbie-pink: #ec4893;
  --black: #333333;
  --bluey-green: #2bb179;
  --bright-cyan: #4ac9f9;
  --burple: #6d40da;
  --butterscotch: #ffb038;
  --brownish-grey: #757575;
  --charcoal-grey: #4d5051;
  --cool-grey: #babbbd;
  --cj-green: #49c581;
  --cloudy-blue: #babbbd;
  --dark: #151c23;
  --dark-grey-blue: #254a5d;
  --dark-sky-blue: #489ade;
  --dull-pink: #e0759d;
  --greeny-blue: #49c5b1;
  --gunmetal: #424c56;
  --grapefruit: #ff5959;
  --light-aqua: #a4e7d9;
  --light-cyan: #b0f2ec;
  --light-indigo: #7a5dc4;
  --light-khaki: #daecb4;
  --light-moss-green: #a5db6a;
  --light-periwinkle: #d8dcdf;
  --lipstick: #cd1e46;
  --mango: #ffb825;
  --manilla: #fae88e;
  --mid-blue: #2a6ca6;
  --medium-blue: #2d60b7;
  --nice-blue: #1a6fba;
  --orange: #fb7615;
  --pale-mauve: #f7e1fb;
  --pastel-red: #e25959;
  --peach: #f9b37f;
  --silver: #d8dcdf;
  --teal-blue: #018599;
  --tealish: #30c4b4;
  --twilight-blue: #102c7e;
  --very-light-blue: #edeef0;
  --warm-grey: #757575;
  --white: #ffffff;

  /* Maintainable Naming Convention */
  /*   1) order (example: primary, secondary, tertiary, quaternary, quinary, etc) */
  /*   2) role (example: brand, button, table-heading, etc) */
  /*   3) state (example: active, inactive, default, disabled, on-hover, etc) */
  /*   4) css property (example: color, font-family, margin, etc) */

  --primary-brand-color: var(--cj-green);
  --primary-button-color: var(--cj-green);

  --icon-default-dark-background-color: #cccccc;
  --icon-default-dark-background-hover-color: var(--white);

  --primary-body-text-color: var(--black);
  --heading-color: var(--black);
  --table-heading-active-color: var(--black);
  --svg-fill-on-hover-color: var(--black);

  --secondary-body-text-color: var(--warm-grey);
  --table-heading-defualt-color: var(--warm-grey);
  --table-heading-inactive-color: var(--warm-grey);
  --svg-fill-defualt-color: var(--warm-grey);

  --text-link-color: var(--nice-blue);

  --panel-color: var(--white);
  --container-color: var(--white);
  --primary-light-text-color: var(--white);

  --page-background-color: #f6f6f6;
  --table-row-highlight: #f6f6f6;

  --border-color: var(--silver);

  --button-background-disabled-color: #edeef0;

  --button-text-disabled-color: #a7a7a7;

  --badge-color: #6d7684;
  --indicator-color: #6d7684;

  --dialog-window-background-color: #4d5051;

  --light-text-color: #bebfc0;

  --validation-success-color: var(--bluey-green);

  --validation-error-color: var(--pastel-red);

  --dark-dropdown-hover-color: #55595a;

  --primary-font-family: "Roboto", "-system-ui", "-apple-system",
    "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
