.pill {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1rem;
  border: 1px solid #babbbd;
  border-radius: 1rem;
  display: inline-block;
  color: #000000;
  padding: 4px 8px;
  text-align: center;
  text-transform: uppercase;
  min-width: fit-content;
}

.pill-inverted {
  color: #ffffff;
  background: #424c56;
  border: 1px solid transparent;
}
