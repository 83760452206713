.information-icon {
  color: var(--secondary-body-text-color);
}

.promotional-model-filter-row span.promotional-models-input {
  padding-left: 4px;
}

.promotional-model-filter-row label {
  display: flex;
  margin-bottom: 0;
}
