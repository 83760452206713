.top-social-overview-table {
  font-size: smaller;
}

.top-social-overview-table .top-social-overview-table-text {
  font-size: 12px;
  color: #333;
  line-height: 1.6;
  text-align: end;
  padding-bottom: 0;
}

.top-social-overview-engagement-label-dropdown {
  width: 210px;
}

.top-social-overview-table .partners-nested-table-column {
  vertical-align: middle;
}

.top-social-overview-left.partners-nested-table-column {
  white-space: normal;
}

.top-social-overview-left .grey {
  color: #5f697a;
}

.nested-th-authentication-status {
  width: 14px;
}

.partners-nested-table-column.top-social-overview-left {
  text-align: left;
}

.partners-nested-table-column.top-social-overview-right {
  text-align: right;
}
