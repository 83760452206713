.earnings {
  width: 32px;
}

.earnings path {
  fill: #d8dcdf;
}

.earnings.earnings-1 path.bar1 {
  fill: #49c5b1;
}

.earnings.earnings-2 path.bar1,
.earnings.earnings-2 path.bar2 {
  fill: #49c5b1;
}

.earnings.earnings-3 path.bar1,
.earnings.earnings-3 path.bar2,
.earnings.earnings-3 path.bar3 {
  fill: #49c5b1;
}

.earnings.earnings-4 path.bar1,
.earnings.earnings-4 path.bar2,
.earnings.earnings-4 path.bar3,
.earnings.earnings-4 path.bar4 {
  fill: #49c5b1;
}

.earnings.earnings-5 path.bar1,
.earnings.earnings-5 path.bar2,
.earnings.earnings-5 path.bar3,
.earnings.earnings-5 path.bar4,
.earnings.earnings-5 path.bar5 {
  fill: #49c5b1;
}
