.vs-dropdown-with-button {
  position: relative;
}

.vs-dropdown-with-button > .vs-dropdown {
  background: var(--white);
  opacity: 0;
  overflow: hidden;
  position: absolute;
  transform-origin: top;
  transform: scaleY(0);
  z-index: 10;
}

.vs-dropdown-with-button > .vs-dropdown.vs-visible {
  opacity: 1;
  transform: scaleY(1);
  transition: transform 0s 0ms, opacity 200ms linear;
}

.vs-dropdown-with-button > .vs-dropdown.vs-visible.vs-direction-up {
  top: -220px;
  height: 220px;
  overflow-y: auto;
  background-color: #444748;
  border-radius: 3px 3px 0 0;
}

.vs-dropdown-with-button > .vs-dropdown.vs-visible.vs-direction-up a {
  text-decoration: none;
}

.vs-dropdown-with-button > .vs-dropdown.vs-visible.vs-direction-up a span {
  color: var(--icon-default-dark-background-color);
}

.vs-dropdown-with-button
  > .vs-dropdown.vs-visible.vs-direction-up
  a:hover
  span {
  color: var(--icon-default-dark-background-hover-color);
  text-decoration: underline var(--white);
}

div.vs-dropdown-expanded button.selected-publishers-button-with-dropup {
  background-color: #55595a;
  border: 1px solid transparent;
  border-radius: 0 0 3px 3px;
}
