.truncate-table-data {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.truncate-table {
  table-layout: fixed;
  width: 100%;
}

.promotional-property-column-pid {
  width: 77px;
}

.promotional-property-column-name {
  width: 140px;
}

.promotional-property-column-type {
  width: 70px;
}

.promotional-property-column-promotional-model {
  width: 145px;
}

.promotional-property-column-details {
  min-width: 20px;
}

.small-property-type-details {
  font-size: 12px;
}

.property-download-button .vsx-btn-child {
  display: flex;
}

.property-reach-metrics {
  line-height: normal;
}
