.vsx-drawer span.drawer-text {
  color: var(--white);
  font-weight: 500;
}

.vsx-drawer span.drawer-expand-icon {
  color: var(--white);
}

.vsx-drawer button.vs-rounded-solid-btn {
  background-color: transparent;
}

.vsx-drawer .vsx-btn.vsx-icon-btn:hover {
  background-color: #626567;
}

.vs-dropdown-with-button > .vs-dropdown.vs-visible.vs-direction-up {
  background-color: #4d5051;
  color: var(--white);
}

.vsx-btn.tertiary.selected-publishers-button-with-dropup {
  width: 300px;
  border-color: var(--light-periwinkle);
  border-radius: 3px;
}

.drop-up-box {
  min-width: 300px;
  max-width: 300px;
}

.selected-publishers-button-with-dropup svg {
  fill: var(--white);
}

.publisher-name-launch-icon {
  margin-left: 5px;
  font-size: 15px;
  display: none;
}

.selected-publisher-name:hover > .publisher-name-launch-icon {
  display: inline;
}

.vsx-drawer a {
  line-height: 1;
}

.vsx-drawer .remove-btn {
  text-transform: capitalize;
  color: var(--icon-default-dark-background-color);
}

.vsx-drawer .vsx-btn.tertiary.selected-publishers-button-with-dropup:hover {
  background: none;
  border-color: #979c9f;
}

.vsx-drawer .vsx-icon-btn svg {
  cursor: pointer;
  color: var(--white);
}

.selected-publishers-dropup
  .vsx-btn.tertiary.selected-publishers-button-with-dropup
  .vsx-btn-child {
  width: 100%;
}
