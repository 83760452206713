.publishers-search-container {
  height: 100%;
}

.partners-table-container {
  height: 100%;
  overflow-y: auto;
}

.partners-table-container .vsx-table span {
  line-height: normal;
}

.am-chart-element {
  width: 325px;
  height: 130px;
}

.piechart-partner-detail {
  width: 50%;
}

.am-map-container {
  width: 325px;
  height: 130px;
}

.am-map-legend {
  float: left;
  width: 40%;
}

.am-map-element {
  float: left;
  width: 60%;
  height: 78%;
  margin-top: 10px;
}

.am-map-context-message {
  margin-top: -10px;
  color: #757575;
  font-size: 10px;
}

.vsx-popover-condensed .vsx-popover-content {
  padding: 0;
  max-height: 320px;
  overflow-y: scroll;
}

.vsx-list-view-condensed .vsx-list-view > .vsx-list-item {
  padding: 12px 16px;
}

.epc-container {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 8px;
  align-items: center;
}

.partners-table-container tbody .vsx-cell:first-child {
  vertical-align: top;
  padding-top: 21px;
}

tr.more-details-data-row td.vsx-cell,
thead.more-details-header th.vsx-cell {
  padding-left: 6px;
  padding-right: 6px;
}

.partners-table-container
  .vsx-table-container-condensed
  .vsx-table-row
  .vsx-cell:first-child {
  padding-left: 16px;
  padding-right: 0;
  width: 16px;
}

.publishers-search-container .publishers-search-header {
  background-color: #fff;
  border-right: 1px solid #eaeaea;
  z-index: 1;
  -webkit-box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.08),
    -1px 2px 3px -1px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.08),
    -1px 2px 3px -1px rgba(0, 0, 0, 0.08);
  box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.08),
    -1px 2px 3px -1px rgba(0, 0, 0, 0.08);
  padding: 8px 16px;
}

.publishers-search-container .publishers-search-header-outside-dimensions {
  border-right: 1px solid #eaeaea;
  z-index: 1;
  padding: 0px 24px;
}

.publishers-search-header-outside-dimensions input.search-input,
.publishers-search-header input.search-input {
  min-width: 250px;
}

.more-details-row .vsx-cell {
  border: 0;
}

.epc-container {
  white-space: nowrap;
}

.partner-details-cell {
  padding: 16px 8px 0;
}

.partner-details-cell a {
  text-decoration: none;
}

.partner-details-cell a:hover {
  text-decoration: underline;
}
