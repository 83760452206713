@import "../../styleConfiguration/globalVariable.css";

.tippy-box {
  background-color: var(--dialog-window-background-color);
  color: var(--primary-light-text-color);
  font-family: var(--primary-font-family);
  font-size: 1.2rem;
  font-weight: 400;
  text-align: center;
}

.tippy-arrow {
  color: var(--dialog-window-background-color);
}

.tippy-content {
  padding: 8px 12px;
}
