svg.nested-table-authentication-status {
  width: auto;
  margin-right: 4px;
  vertical-align: baseline;
}

span.nested-table-authentication-status {
  display: inline-block;
  width: 17px;
}
