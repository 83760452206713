@import "../../../styleConfiguration/globalVariable.css";

.filters-panel ul {
  margin: 0px;
  padding: 0px;
}

.filters-panel li {
  list-style: none;
}

.vsx-sliding-panel {
  border-left: 0;
  padding-bottom: 66px;
  margin: 0;
  height: auto;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08),
    0 2px 3px -1px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08),
    0 2px 3px -1px rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08), 0 2px 3px -1px rgba(0, 0, 0, 0.08);
}

.vsx-sliding-panel .vsx-sliding-panel-dropdown,
.filters-panel .vsx-sliding-panel-section-container,
.vsx-box.vsx-sliding-panel-dropdown {
  border-bottom: 1px solid #d1d1d2;
  border-top: 0;
  padding-right: 8px;
}

.vsx-sliding-panel-section-container
  .vsx-sliding-panel-section-container-label
  .vsx-sliding-panel-chevron-container
  svg {
  fill: #333;
}

.vsx-sliding-panel .vsx-sliding-panel-header,
.filters-panel .vsx-sliding-panel-header {
  text-transform: none;
  padding-bottom: 10px;
}

.tree-container {
  max-height: 198px;
  overflow-y: auto;
  line-height: 22.4px;
}

.vs-tree .vs-labelContent > div,
.vs-tree .vs-leafContent > div {
  order: 2;
  margin-left: 8px;
}

.vs-tree .vs-labelContent > input,
.vs-tree .vs-leafContent > input {
  order: 1;
  margin-top: 5px;
}

.vs-choice-input label,
.vsx-choice-input input {
  cursor: pointer;
}

.popover-dashed-underline {
  border-bottom: 1px dashed #000;
  margin-left: 4px;
}

#filters-tooltip .vsx-popover-wrapper .vsx-mdi-icon {
  color: var(--secondary-body-text-color);
}

.property-types-container .vs-leafContent > div,
.property-types-container .vs-labelContent > div {
  display: flex;
  flex: 1;
}

.property-types-container .vs-leafContent > div > span,
.property-types-container .vs-labelContent > div > span {
  display: flex;
  flex: 1;
  line-height: 1.6;
}

.property-types-container .vs-leafContent > div > div,
.property-types-container .vs-labelContent > div > div {
  display: flex;
}

.div.vs-badge {
  min-width: fit-content;
}

#filters-tooltip .vsx-popover-reference {
  flex-direction: row;
  align-items: center;
}

.tree-container {
  border: 1px solid #c8ccce;
  border-radius: 3px;
  padding: 4px 8px;
}

.vsx-choice-input input {
  margin: 0;
}

.vsx-choice-input label {
  display: flex;
  gap: 4px;
}
