.vsx-btn .favorite-icon {
  cursor: pointer;
  color: var(--warm-grey);
}

.favorite-icon:hover {
  color: var(--black);
}

.vsx-btn.vsx-icon-btn.favorite-icon-filled {
  cursor: pointer;
  color: var(--greeny-blue);
}

.vsx-btn.is-favorited:hover,
.vsx-btn.is-favorited:hover svg {
  fill: var(--greeny-blue);
}

.vsx-drawer.vsx-drawer-open .vsx-btn.tertiary.favorite-icon {
  fill: var(--white);
  cursor: pointer;
}
