@import "../../../../styleConfiguration/globalVariable.css";

.vsx-layout-section.matching-properties-section {
  border: none;
}

.vsx-layout-section.matching-properties-section > .vsx-box {
  flex-direction: row-reverse;
  justify-content: flex-end;
  padding-left: 0;
}

.vsx-layout-section.matching-properties-section .vsx-layout-section-children {
  padding: 7px 8px 4px 0;
}

.vsx-layout-section.matching-properties-section .vsx-layout-section-title {
  font-size: 12px;
}

.matching-properties-section .vsx-cell {
  border: 0;
}

.matching-properties-section .more-details-header {
  border-bottom: 1px solid #c8ccce;
}

.vsx-box .matching-properties {
  display: grid;
  column-gap: 64px;
  grid-template-columns: min-content min-content min-content;
  white-space: nowrap;
  align-items: center;
}

.partners-nested-table-container {
  width: 100%;
  display: table;
}

.partners-nested-table-body {
  display: table-row-group;
}

.partners-nested-table-header {
  display: table-header-group;
  border-bottom: 1px solid #e6e6e6;
  font-size: 12px;
  color: var(--table-heading-default-color);
  font-weight: 500;
  height: 24px;
}

.partners-nested-table-row {
  display: table-row;
  height: 24px;
}

.partners-nested-table-column {
  display: table-cell;
  text-align: left;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.partners-nested-table-column + .partners-nested-table-column {
  padding-left: 10px;
}

.partners-nested-name {
  max-width: 110px;
}

.partners-nested-type {
  max-width: 70px;
}

.partners-nested-details {
  max-width: 240px;
}

.partners-nested-promotional-model {
  max-width: 150px;
}

.partners-nested-reach {
  max-width: 150px;
}

.partners-nested-reach .vsx-box {
  margin-top: 3px;
}
