.vsx-popover-reference {
  cursor: pointer;
}

.vsx-react-select-value-container {
  font-size: 1.3rem;
}

.vsx-popover .tippy-content {
  text-align: left;
}

.shadow {
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08),
    0 2px 3px -1px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08),
    0 2px 3px -1px rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08), 0 2px 3px -1px rgba(0, 0, 0, 0.08);
}

.modal-dialog .modal-content {
  top: unset;
}

.tree-highlight {
  font-weight: bold;
}
