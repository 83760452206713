.share-url {
  border: 1px solid var(--silver);
  text-align: left;
  padding: 4px 8px;
  width: 100%;
  word-break: break-word;
  max-height: 100px;
  overflow-y: scroll;
}

.vs-alert-content {
  padding-top: 0;
}

.share-button-alert {
  align-items: center;
  width: 400px;
}

.copy-textarea {
  visibility: hidden;
}
