.social-stats-title {
  border-bottom: 1px solid #c8ccce;
  border-radius: 2px;
}

.social-stats-grid {
  grid-template-columns: repeat(auto-fit, 120px);
  justify-items: center;
}

.social-stat {
  width: fit-content;
  line-height: normal;
}

.social-stat-center {
  text-align: center;
}
