@import "../../../styleConfiguration/globalVariable.css";

.vsx-table-container.vsx-table-container-condensed {
  background-color: #ffffff;
  padding: 0px;
  border: 0px;
  min-width: min-content;
  margin: 0;
}

.vsx-table-container-condensed .vsx-table-title {
  padding: 16px 16px 12px 16px;
}

.vsx-table-container-condensed .vsx-cell {
  padding: 0px 8px;
}

.vsx-table-container-condensed .vsx-table-title {
  margin: 0px;
}

.vsx-table-container-condensed .vsx-table > tbody > tr:hover {
  background-color: unset;
}

.vsx-table-container-condensed .vsx-table .vsx-thead .vsx-cell {
  color: var(--warm-grey);
  border-bottom: 0px;
  border-top: 0px;
}

.vsx-table-container-condensed .vsx-thead .vsx-cell.partners-search-header {
  padding: 8px 16px;
}

.vsx-table-container-condensed .vsx-table-toolbar {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin: -4px;
}

.favoritesBlankSlateWrapper svg {
  width: 40px;
  height: 38px;
}

.vsx-table-container-condensed .vsx-table-toolbar-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 4px;
}

.blank-slate-subtitle {
  display: inline-block;
  width: 50%;
  max-width: 355px;
  line-height: 1.6;
  color: var(--secondary-body-text-color);
}

.publisher-list-description {
  width: 320px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.publisher-list-category-name,
.publisher-list-country-name {
  max-width: 200px;
  overflow-wrap: break-word;
}

.vsx-table .vsx-thead .vsx-cell {
  font-size: 12px;
  color: var(--table-heading-default-color);
}

.vs-collapsible-panel-header-title {
  font-size: 12px;
}

.publisher-result-row {
  border-top: 1px solid #e6e6e6;
}

.publisher-result-row-hidden {
  display: none;
}

.vsx-tbody.publisher-result-row span.vsx-text.publisher-name {
  font-size: 14px;
  cursor: pointer;
}

.partners-table-container .fit-content {
  width: fit-content;
  height: fit-content;
}

.vsx-alert-d {
  align-items: center;
  transform: translateY(80vh);
}

.vsx-alert-content .vsx-btn-d {
  position: relative;
  margin-left: 24px;
}

.publisher-search-action-toolbar {
  align-items: flex-start;
}

.publisher-checkbox-container label,
.publisher-checkbox-container input {
  margin: 0;
}

.vsx-box .table-title {
  line-height: normal;
}
