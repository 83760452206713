.min-max-slider {
  --thumbBg: var(--white);
  --thumbBorder: 1px solid var(--silver);
  --thumbBorderFocus: 1.5px solid var(--warm-grey);
  --trackBg: var(--silver);
  --progressBg: var(--warm-grey);

  --thumbSize: 16px;
  --trackSize: 4px;

  --trackBorderRadius: calc(var(--trackSize) / 2);
  --thumbBorderRadius: calc(var(--thumbSize) / 2);
  --thumbSelectorSize: calc(var(--thumbSize) - 2);

  /* webkit progress workaround */
  --webkitProgressPercent: 0%;
}

.min-max-slider {
  position: relative;
  text-align: left;
  height: var(--trackSize);
  width: 100%;
  margin: 8px 0;
  padding: 0px 10px 0px 0px;
}

.min-max-slider > div {
  position: absolute;
  left: 13px;
  right: 15px;
  height: var(--trackSize);
}

.min-max-slider > div > .inverse-left {
  position: absolute;
  left: 0;
  margin: 0 7px 0 -13px;
  height: var(--trackSize);
  background-color: var(--trackBg);
  border-radius: var(--trackBorderRadius);
}

.min-max-slider > div > .inverse-right {
  position: absolute;
  right: 0;
  height: var(--trackSize);
  background-color: var(--trackBg);
  border-radius: var(--trackBorderRadius);
  border-left: var(--trackBg) 3px solid;
}

.min-max-slider > div > .range {
  position: absolute;
  left: 0;
  border-radius: 14px;
  height: var(--trackSize);
  background-color: var(--progressBg);
  border-radius: var(--trackBorderRadius);
}

.min-max-slider > div > .thumb {
  position: absolute;
  text-align: left;
  margin-left: -12px;
  outline: none;
  width: var(--thumbSize);
  height: var(--thumbSize);
  background-color: var(--thumbBg);
  border-radius: var(--thumbBorderRadius);
  border: var(--thumbBorder);
  margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
  cursor: pointer;
}

.min-max-slider > div > .thumb.hover {
  border: var(--thumbBorderFocus);
}

.min-max-slider > input[type="range"] {
  position: absolute;
  pointer-events: none; /* allows user to click through slider bar */
  -webkit-appearance: none;
  top: -8px;
  width: 100%;
  height: var(--trackSize);

  /* invisible range css */
  /* ======================== */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
}

/* track modifiers */

div.min-max-slider > input[type="range"]::-ms-track {
  -webkit-appearance: none;
  background: transparent;
  color: transparent;
}

div.min-max-slider > input[type="range"]::-moz-range-track {
  -moz-appearance: none;
  background: transparent;
  color: transparent;
}

div.min-max-slider > input[type="range"]:focus::-webkit-slider-runnable-track {
  background: transparent;
  border: transparent;
}

/* focus modifier */

div.min-max-slider > input[type="range"]:focus {
  outline: none;
}

/* thumb */

div.min-max-slider > input[type="range"]::-ms-thumb {
  pointer-events: all;
  border: 0 none;
  background: red;
  width: 16px;
  height: 16px;
  border-radius: var(--thumbBorderRadius);
}

div.min-max-slider > input[type="range"]::-moz-range-thumb {
  pointer-events: all;
  border: 0 none;
  background: red;
  width: 16px;
  height: 16px;
  border-radius: var(--thumbBorderRadius);
}

div.min-max-slider > input[type="range"]::-webkit-slider-thumb {
  pointer-events: all; /* allows user to click slider thumb but even though they can't click slider bar */
  border: 0 none;
  background: red;
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border-radius: var(--thumbBorderRadius);
}

/* fill */

div.min-max-slider > input[type="range"]::-ms-fill-lower {
  background: transparent;
  border: 0 none;
}

div.min-max-slider > input[type="range"]::-ms-fill-upper {
  background: transparent;
  border: 0 none;
}

div.min-max-slider > input[type="range"]::-ms-tooltip {
  display: none;
}

/* input field styling */
.min-max-slider-input input[type="text"] {
  background-color: var(--color-background-default);
  color: var(--color-text-default);
  border: 1px solid #c8ccce;
  border-radius: 3px;
  box-sizing: border-box;
  font: inherit;
  border-radius: 3px;
  height: 32px;
  line-height: 1.6;
  padding: 3px 10px;
  transition: border-color 0.2s;
  width: 100%;
}
